export default {
  namespaced: true,
  state: {
    caseLists: [],
    count: 0,
    reportCaseID: null,
  },
  getters: {
    caseLists: (state) => state.caseLists,
    count: (state) => state.count,
    reportCaseID: (state) => state.reportCaseID,
  },
  mutations: {
    caseLists: (state, payload) => (state.caseLists = payload),
    count: (state, payload) => (state.count = payload),
    reportCaseID: (state, payload) => (state.reportCaseID = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/customer-case-lists", {
          params: queryParams,
        })
        .then((res) => {
          commit("caseLists", res?.data?.data ?? []);
          commit("count", res?.data?.total ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/case-lists", payload);
    },
    show({}, queryParams) {
      return this.$apiService.get(`/case-lists`, {
        params: queryParams,
      });
    },
    dashboard({}, queryParams) {
      return this.$apiService.get(`/dashboard`, {
        params: queryParams,
      });
    },
    updateFlag({}, payload) {
      return this.$apiService.post("/update-flags", payload);
    },
    updateStatuses({}, payload) {
      return this.$apiService.post("/update-statuses", payload);
    },
    updateStatus({}, payload) {
      return this.$apiService.post("/update-status", payload);
    },
    replicateCase({}, payload) {
      return this.$apiService.post("/requeue-case", payload);
    },
    deleteCases({}, payload) {
      return this.$apiService.post("/delete-cases", payload);
    },
    emptyTrash({}) {
      return this.$apiService.post("/empty-trash");
    },
    destroy({}, id) {
      return this.$apiService.delete(`/case-lists/${id}`);
    },
    getCreditCosts({}) {
      return this.$apiService.get(`/get-global-configuration`);
    },
    saveRating({}, payload) {
      return this.$apiService.post("/save-rating", payload);
    }
  },
};
